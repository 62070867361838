
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../../helper/api";
import { setCookies } from "../../helper/utils";

import { toast } from "react-toastify";

export const addBalance = createAsyncThunk("user/addBalance", async (payload) => {
  const response = await api.post("user/add-balance", payload);
 
  return response.data;
});

const addBalanceSlice = createSlice({
  name: "user",
  initialState: {
    data: null,
    loading: false,
    error: null,
  },
  reducers: {},
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: {
      ignoredPaths: ["payload.headers"],
    },
  }),
  extraReducers: (builder) => {
    builder
      .addCase(addBalance.pending, (state) => {
       
        state.loading = true;
      })
      .addCase(addBalance.fulfilled, (state, action) => {
        
      
       
        state.loading = false;
        console.log(action.payload)
        state.data = action.payload;
        toast.success(action.payload.message)
      })
      .addCase(addBalance.rejected, (state, action) => {
       
        state.loading = false;
        state.error = action.error.message;
        toast.error(action.error.message);
      });
  },
});

export default addBalanceSlice.reducer;
