import React from "react";
import PropTypes from "prop-types";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const MobileInput = ({
  name,
  title,
  type,
  value,
  error,
  handleChange,
  className,
  placeholder,
}) => {

  const handleMobile = (e, data) => {
   
    handleChange({name: name, value: e, country_code: data.countryCode})
  }
  return (
    <div className="d-flex mb-2 mx-2 w-fit-content">
      {title && (
        <label className="d-flex align-items-center fs-12 fw-semibold mb-2">
          <span className="required">{title}</span>
        </label>
      )}

      <PhoneInput
        country={"us"}
        name={name}
        className={`${className} w-100`}
        value={value}
        onChange={(e, data) => {
          
          
          handleMobile(e, data)
        }}
      />

      <span className="fs-10 text-danger">{error}</span>
    </div>
  );
};

MobileInput.prototype = {
  name: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.any,
  error: PropTypes.string,
  handleChange: PropTypes.func,
  placeholder: PropTypes.string,
};
export default MobileInput;
