
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../../helper/api";
import { setCookies } from "../../helper/utils";
import { toast } from "react-toastify";

export const userLogin = createAsyncThunk("user/userLogin", async (payload) => {
    // "token": "abc123tokenxyz"
  const response = await api.post("user/login", payload);

  return response.data;
});

const userLoginSlice = createSlice({
  name: "user",
  initialState: {
    data: null,
    loading: false,
    error: null,
  },
  reducers: {},
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: {
      ignoredPaths: ["payload.headers"],
    },
  }),
  extraReducers: (builder) => {
    builder
      .addCase(userLogin.pending, (state) => {
       
        state.loading = true;
      })
      .addCase(userLogin.fulfilled, (state, action) => {
        
        // setCookies('token', action.payload.data.data.token)
        // setCookies('role', action.payload.data.data.role)
       
        state.loading = false;
        
        state.data = action.payload.data;
      })
      .addCase(userLogin.rejected, (state, action) => {
       
        state.loading = false;
        state.error = action.error.message;
        toast.error(action.error.message);
      });
  },
});

export default userLoginSlice.reducer;
