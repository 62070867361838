import React from "react";
import PropTypes from "prop-types";

const Checkbox = ({
  name,
  title,
  type,
  value,
  label,
  error,
  handleChange,
  placeholder,
  className,
}) => {
  
  return (
    <div className=" mb-2">
      <label className="form-check form-check-custom form-check-inline form-check-solid mx-2">
        <input
          className="form-check-input"
          name={name}
          type="checkbox"
          value="1"
        />
        <span className="fw-semibold ps-2 fs-6">{label}</span>
      </label>

      <span className="fs-10 text-danger">{error}</span>
    </div>
  );
};

Checkbox.prototype = {
  name: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.any,
  label: PropTypes.string,
  error: PropTypes.string,
  handleChange: PropTypes.func,
  placeholder: PropTypes.string,
  className: PropTypes.string,
};
export default Checkbox;
