import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

const TextInput = ({
  name,
  title,
  type,
  value,
  error,
  handleChange,
  placeholder,
  className,
  prefix,
  postfix,
  sideTitle,
  additionalPre,
  additionalPost,
  addon1,
  addon2,
  handleAddon1,
  handleAddon2,
  addonBtn1,
  addonBtn2,
  handleAddonBtn1,
  handleAddonBtn2,
  minusAllow,
}) => {
  const [reset, setReset] = useState(false)

  useEffect(() => {
    setReset(false)
  }, [name])
  const handleKeyPress = (e) => {
    console.log(31, e.key, e.charCode, minusAllow);
    if (type == "number" && minusAllow) {
      console.log(33,)
      const allowedKeys = ["Backspace", "ArrowLeft", "ArrowRight", "-", "."];

      if (allowedKeys.includes(e.key)) {
        // Allow navigation, deletion, and special keys
        return true;
      }

      // Check if the key pressed is a number or a negative sign at the start
      const regex = /^-?[0-9]*\.?[0-9]*$/;
      const inputValue = e.target.value + e.key;

      if (!regex.test(inputValue)) {
        e.preventDefault();
      }

      // pattern="^[+-]?([0-9]*[.])?[0-9]+$",  ^-?\d+$

      // if (e.charCode !== 46) {
      //   console.log(e.key.match(/^-?[0-9]*\.?[0-9]+$/))
      //   if (e.key.match(/^-?[0-9]*\.?[0-9]+$/) === null) {

      //     e.preventDefault();
      //   } else {

      //   }
      // } else {
      // }
    } else if (type == "number" && !minusAllow && e.key !== '-') {
      const allowedKeys = ["Backspace", "ArrowLeft", "ArrowRight", "."];

      if (allowedKeys.includes(e.key)) {
        console.log(64)
        // Allow navigation, deletion, and special keys
        return true;
      }

      // Check if the key pressed is a number or a negative sign at the start
      const regex = /^-?[0-9]*\.?[0-9]*$/;
      const inputValue = e.target.value + e.key;

      if (!regex.test(inputValue)) {
        console.log(73)
        e.preventDefault();
      } else {
        console.log(76)
        
      }

     
    } else {
      console.log(84)
      e.preventDefault();
      return false
    }
  };
  return (
    <div className=" mx-1 mx-sm-1 mx-md-2  w-100 w-sm-100">
      {title && (
        <label className="d-flex align-items-center justify-content-between fs-12 input-label">
          <span className="">{title}</span>
          {sideTitle && <span className="">{sideTitle}</span>}
        </label>
      )}

      <InputGroup className={`  ${className} `}>
        {/*  mb-1 mb-sm-2 mb-md-2 mb-lg-2 */}
        {prefix && <InputGroup.Text>{prefix}</InputGroup.Text>}
        {additionalPre && (
          <InputGroup.Text
            className={`p-0 w-50 d-flex align-items-center justify-content-between fs-12 input-label addons  `}
          >
            {addonBtn1 && (
              <span
                className={reset ? "btn fs-12 addon-btn px-1 cursor-pointer py-2" : "addon-btn-disable btn fs-12 px-1 cursor-pointer py-2"}
                onClick={() => {
                  setReset(true)
                  handleAddonBtn1(name);
                }}
              >
                {addonBtn1}{" "}
              </span>
            )}

            {addonBtn2 && (
              <span
              className={!reset ? "btn fs-12 addon-btn px-1 cursor-pointer py-2" : "addon-btn-disable btn fs-12 px-1 cursor-pointer py-2"}
                onClick={() => {
                  setReset(false)
                  handleAddonBtn2(name);
                }}
              >
                {addonBtn2}
              </span>
            )}
          </InputGroup.Text>
        )}
        <Form.Control
        
        disabled={(['onWin','onLoss'].includes(name) && reset) ? true : false}
          className="form-control-solid"
          type={type !== "number" ? type : "text"}
          name={name}
          onKeyPress={handleKeyPress}
          placeholder={placeholder}
          step={"any"}
          value={value}
          onChange={(e) => {
            handleChange(e);
          }}
        ></Form.Control>
        {postfix && (
          <InputGroup.Text className={"fs-12"}>{postfix}</InputGroup.Text>
        )}
        {additionalPost && (
          <InputGroup.Text className="d-flex align-items-center justify-content-between fs-12 input-label addons">
            {addon1 && (
              <span
                className=" cursor-pointer"
                onClick={() => {
                  handleAddon1();
                }}
              >
                {addon1}{" "}
              </span>
            )}
            <div className="vr mx-1"></div>
            {addon2 && (
              <span
                className="cursor-pointer"
                onClick={() => {
                  handleAddon2();
                }}
              >
                {addon2}
              </span>
            )}
          </InputGroup.Text>
        )}
      </InputGroup>

      <span className="fs-10 text-danger">{error}</span>
    </div>
  );
};

TextInput.prototype = {
  name: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.any,
  error: PropTypes.string,
  handleChange: PropTypes.func,
  placeholder: PropTypes.string,
  className: PropTypes.string,
};
export default TextInput;
