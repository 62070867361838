import React from "react";
import PropTypes from "prop-types";

const Select = ({
  name,
  title,
  value,
  error,
  options,
  handleChange,
  placeholder,
  className,
}) => {
  return (
    <div className=" mx-1 mx-sm-1 mx-md-2 w-100 w-sm-100">
      {title && (
        <label className="d-flex align-items-center justify-content-between fs-12 input-label mb-1 ">
          <span className="required">{title}</span>
         
          
        </label>
      )}
      <select
        name={name}
        value={value}
        aria-label="Select a Currency"
        data-control="select2"
        data-placeholder="Select a currency.."
        className=" form-select-solid form-select "
        onChange={handleChange}
      >
        <option value="">Select a {title}..</option>
        {options?.map((item, index) => (
          <option className="text-capitalize" key={index} value={item.value}>
            {item.label}
          </option>
        ))}
      </select>
      <span className="fs-10 text-danger">{error}</span>
    </div>
  );
};

Select.prototype = {
  name: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.any,
  error: PropTypes.string,
  handleChange: PropTypes.func,
  placeholder: PropTypes.string,
  className: PropTypes.string,
};

export default Select;
