import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import PrivateRoute from "./private-route";
import { privateRoutes } from "./route-list";



const Loader = () => {
  return (
    <div className="loader-box">
      <div className="loader-15"></div>
    </div>
  );
};
function DefaultRoutes() {
  return (
    <section className="page">
      <React.Suspense fallback={<Loader />}>
        <Router>
          <Routes>
            {/* <Route path="/" element={<HomeScreen />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
             */}

            {privateRoutes.map((itm, index) => (
              <Route
                key={index}
                path={itm.path}
                exact
                element={
                  <PrivateRoute
                    name={itm.path}
                    path={itm.path}
                    component={itm?.layout}
                    element={itm?.layout}
                    isAuthProtected={itm.isAuthProtected}
                  />
                }
              />
            ))}
          </Routes>
        </Router>
      </React.Suspense>
    </section>
  );
}

export default DefaultRoutes;
