import React, { lazy } from "react";
import Login from "../pages/auth/login";
const Signup = lazy(() => import("../pages/auth/signup"));
const HomeScreen = lazy(() => import("../pages/home-screen"));
const Profile = lazy(() => import("../pages/profile"));
const GameHistory = lazy(() => import("../pages/game-history"));

export const openRoutes = [
  {
    layout: <Login />,
    path: "/",
    name: "Login",
    isAuthProtected: false,
  },
  {
    layout: <Signup />,
    path: "/signup",
    name: "Signup",
    isAuthProtected: false,
  },
];
export const privateRoutes = [
  {
    layout: <HomeScreen />,
    path: "",
    name: "HomeScreen",
    isAuthProtected: true,
  },
  {
    layout: <Profile />,
    path: "/profile",
    name: "Profile",
    isAuthProtected: true,
  },
  {
    layout: <GameHistory />,
    path: '/game-history',
    name: "GameHistory",
    isAuthProtected: true,
  },
];
