import React from "react";
import PropTypes from "prop-types";


const Radio = ({
    name,
    title,
    type,
    value,
    error,
    handleChange,
    placeholder,
    className,
  }) => {
   
    return (
      <div className="w-100 mx-2 mb-2">
        {title &&  <label className="d-flex align-items-center fs-12 fw-semibold mb-2">
          <span className="required">{title}</span>
        </label> }
       
  
        <div className="form-check form-check-solid form-switch form-check-custom">
                  <input
                    className="form-check-input w-45px h-30px"
                    type="checkbox"
                    id="allowmarketing"
                    
                  />
                  <label
                    className="form-check-label"
                    for="allowmarketing"
                  ></label>
                </div>
        <span className="fs-10 text-danger">{error}</span>
      </div>
    );
  };
  
  Radio.prototype = {
    name: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.any,
    error: PropTypes.string,
    handleChange: PropTypes.func,
    placeholder: PropTypes.string,
    className: PropTypes.string,
  };
  export default Radio;
  