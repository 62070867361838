import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { login } from "../../redux/slices/loginSlice";
import {TextInput, Button} from "../../components/index"

import { loginValidation } from "../../validation/signup";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [error, setError] = useState({});

  const [form, setForm] = useState({ email: "", password: "" });
  const {
    loading,
    error: loginError,
    data,
  } = useSelector((state) => state.login);

 

 

  const handleSubmit = (e) => {
    e.preventDefault();
    
    const { errors, isValid } = loginValidation(form);
    console.log(errors, isValid);
    if (!isValid) {
      setError(errors);
    } else {
    dispatch(login(form))
      .unwrap()
      .then((res) => {
        console.log(42, res)
        navigate("home-screen");
      })
      .catch((error) => {
        console.error("Failed to fetch user:", error);
      });
    
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    setForm((prev) => ({ ...prev, [`${name}`]: value }));
    setError((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  return (
    <div className="center-div">
      <form>
       
        <TextInput
                  name={"email"}
                  title={"Email"}
                  type={"text"}
                  value={form.email}
                  error={error.email}
                  handleChange={handleChange}
                  placeholder={"Email"}
                  className={"input-group-large"}
                />

        <TextInput
          name={"password"}
          title={"password"}
          type={"password"}
          value={form.password}
          error={error.password}
          handleChange={handleChange}
          placeholder={"Password"}
          className={"input-group-large"}
        />

        <div className="text-end fs-12 cursor-pointer fw-semibold my-2">
          <div className="link-primary cursor-pointer" onClick={() => {
            navigate('forgot-password')
          }}>Forgot Password?</div>
        </div>

        <Button name={"Sign In"} handleSubmit={handleSubmit} type={"submit"} />

        <div className="text-gray-500 text-center fw-semibold fs-12">
          Not a Member yet?{" "}
          <span
            className="link-primary"
            onClick={() => {
              navigate("signup");
            }}
          >
            Sign up
          </span>
        </div>
      </form>
      <iframe src={'https://kenof.staging-server.in/'} height={500} width={500}/>         
    </div>
  );
};
export default Login;
