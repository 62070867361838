import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import HumburgerMenu from "../pages/layout/sidebar";
import { userLogin } from "../redux/slices/userLoginSlice";

import { sidebarDrawerInit, getCookies, setCookies } from "../helper/utils";
import { USER_TOKEN } from "../helper/api";

const PrivateRoute = ({ path, element }) => {
  const dispatch = useDispatch();
  const auth = getCookies("token");
  const [menuOpen, setMenuOpen] = useState(sidebarDrawerInit ? false : true);
  const { innerWidth: width } = window;
  const drawerWidth = window.innerWidth > 567 ?  "201px" : '0px';

  let forSmallScreen = menuOpen ? drawerWidth : "0px";
  let forLargeScrren = menuOpen ? drawerWidth : "50px"; // 50px
  let newMargin = width <= 768 ? forSmallScreen : forLargeScrren;

  if (window.top !== window.self) {
    console.log("This page is in an iframe. ooo", document.referrer);
    console.log("ooo Parent URL:", document.referrer, document.cookie);
  } else {
    console.log("ooo This page is not in an iframe.", document.referrer);
  }
  window.addEventListener(
    "onload",
    (event) => {
      if (event.origin !== "http://192.168.2.165:3001/") return;

      console.log(32)
     
    },
    false
  );

  //  useEffect(() =>{
  const trustedOrigins = ["http://192.168.2.165:3001/"];

  function onMsg(msg) {
    console.log("msg", msg);
    if (!trustedOrigins.includes(msg.origin)) return;
    console.log(`Message from main window`, msg);
    window.parent.postMessage(
      JSON.stringify({
        message: "this should be delivered to main window",
      }), 'http://192.168.2.165:3000/'
    );
  }

  window.addEventListener("onload", onMsg, false);
  //  }, [])

  useEffect(() => {
    const handleMessage = (event) => {
      console.log(57, event)
      // In production, replace with your child app's actual origin
      if (event.origin !== "http://192.168.2.165:3001/") return;

      if (event.data.type === 'TOKEN') {
       
        console.log('Received token:', event.data.token)
      }
    }

    window.addEventListener('message', handleMessage)

    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [])


  useEffect(() => {
    getUserLogin();
  }, []);

  const getUserLogin = () => {
    const payload = {
      token: USER_TOKEN,
    };
    dispatch(userLogin(payload))
      .unwrap()
      .then((res) => {
        setCookies("token", res.data.token);
        setCookies("user_id", res.data._id);
        setCookies("wallet_balance", res.data.wallet_balance);
      })
      .catch((error) => {
        console.error("Failed to fetch user:", error);
      });
  };

  return auth ? (
    <div className="wrapper">
      
      <div className={"page-wrap"}>
        <HumburgerMenu menuOpen={menuOpen} setMenuOpen={setMenuOpen} />

        <div
          style={{
            marginLeft: newMargin,
            transition: "all 0.4s ease",
          }}
        >
          {element}
        </div>
      </div>
      {/* < Temp /> */}
    </div>
  ) : (
    <Navigate to="/" />
  );
};


const Temp = () => {
  return (
    <div
      style={{
        fontFamily: "Arial, sans-serif",
        margin: 0,
        padding: 0,
        backgroundColor: "#f9f9f9",
        color: "#333",
      }}
    >
      <div
        style={{
          maxWidth: "800px",
          margin: " 20px auto",
          background: "#fff",
          border: "1px solid #ddd",
          borderRadius: "5px",
          padding: "20px",
          boxShadow: " 0 2px 5px rgba(0, 0, 0, 0.1)",
        }}
      >
        <header style={{ borderBottom: "2px solid #007BFF", paddingBottom: "10px", marginBottom: "20px"}} >
          <h1 style={{ margin: 0, color: "#007BFF", fontSize: "24px"}} >Invoice</h1>
          <p style={{ margin: "5px 0"}} >
            Invoice #: <strong>#12345</strong>
          </p>
          <p style={{ margin: 0}} >
            Date: <strong>25th November 2024</strong>
          </p>
        </header>

        <section style={{ marginBottom: "20px"}} >
          <h2 style={{ marginBottom: "10px", fontSize: "18px", borderBottom: "1px solid #ddd", paddingBottom: "5px",}} >
            Bill To
          </h2>
          <p style={{ margin: "5px 0"}} >John Doe</p>
          <p style={{ margin: "5px 0"}} >123 Main Street</p>
          <p style={{ margin: "5px 0"}} >City, State, ZIP</p>
          <p style={{ margin: "5px 0"}} >Email: john.doe@example.com</p>
        </section>

        <table style={{ width: "100%", borderCollapse: "collapse", marginBottom: "20px"}} >
          <thead>
            <tr>
              <th style={{ textAlign: "left", borderBottom: "2px solid #007BFF", padding: "10px"}} >
                Description
              </th>
              <th style={{textSlign: "center", borderBottom: "2px solid #007BFF", padding: "10px"}}>
                Quantity
              </th>
              <th style={{textSlign: "right", borderBottom: "2px solid #007BFF", padding: "10px"}}>
                Price
              </th>
              <th style={{textSlign: "right", borderBottom: "2px solid #007BFF", padding: "10px"}}>
                Total
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ padding: "10px", borderBottom: "1px solid #ddd"}} >
                Product A
              </td>
              <td style={{padding: "10px", textAlign: "center", borderBottom: "1px solid #ddd"}}>
                2
              </td>
              <td style={{ padding: "10px", textAlign: "right", borderBottom: "1px solid #ddd"}} >
                $50.00
              </td>
              <td style={{ padding: "10px", textAlign: "right", borderBottom: "1px solid #ddd"}} >
                $100.00
              </td>
            </tr>
            <tr>
              <td style={{ padding: "10px", borderBottom: "1px solid #ddd"}} >
                Service B
              </td>
              <td style={{ padding: "10px", textAlign: "center", borderBottom: "1px solid #ddd"}} >
                1
              </td>
              <td style={{ padding: "10px", textAlign: "right", borderBottom: "1px solid #ddd"}} >
                $200.00
              </td>
              <td style={{ padding: "10px", textAlign: "right", borderBottom: "1px solid #ddd"}} >
                $200.00
              </td>
            </tr>
          </tbody>
        </table>

        <div style={{ textAlign: "right"}} >
          <p style={{ margin: "5px 0"}} >
            <strong>Subtotal:</strong> $300.00
          </p>
          <p style={{ margin: "5px 0"}} >
            <strong>Tax (10%):</strong> $30.00
          </p>
          <p style={{ margin: "5px 0", fontSize: "18px"}} >
            <strong>Total:</strong> $330.00
          </p>
        </div>

        <footer style={{ marginTop: "30px", borderTop: "2px solid #007BFF", paddingTop: "10px", textAlign: "center", fontSize: "12px", color: "#666"}} >
          <p style={{ margin: "5px 0"}} >Thank you for your business!</p>
          <p style={{ margin: 0}} >
            If you have any questions, please contact us at{" "}
            <a
              href="mailto:support@example.com"
              style={{ color: "#007BFF", textDecoration: "none"}}
            >
              support@example.com
            </a>
          </p>
        </footer>
      </div>
    </div>
  );
};

export default PrivateRoute;
