import axios from "axios";
import { getCookies, deleteCookies } from "./utils";

export const apiURL = "https://kenob.staging-server.in/";
export const USER_TOKEN = 'abc123tokenxyz';
export const GAME_ID = '67319e64d3c4fd787ffc700c'
export const recaptchaKey = '';

export const api = axios.create({
  baseURL: apiURL,
});

api.defaults.headers = {
  "Access-Control-Allow-Origin": `*`,
  "Access-Control-Allow-Methods": "GET,POST,PUT,DELETE,PATCH,OPTIONS",
  "Content-Type": "application/json; charset=utf-8",
  Accept: "*/*",
};
api.interceptors.request.use(
  function (config) {
    config.headers.Authorization = `Bearer ${getCookies("token")}`;
    // config.headers["Content-Type"] = "application/json; charset=utf-8"
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
   
    if (response?.status === 401) {
      deleteCookies("token");
      setTimeout(() => {
        window.location.href = "/";
      }, 1500);
    }
    return response;
  },
  function (error) {
    
    if (error?.response?.data.code === 401) {
      setTimeout(() => {
        window.location.href = "/";
      }, 1500);
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

export default api;
