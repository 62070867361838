import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import { addBalance } from "../redux/slices/addBalanceSlice";
import { userLogin } from "../redux/slices/userLoginSlice";

import { getCookies, setCookies } from "../helper/utils";
import { USER_TOKEN } from "../helper/api";

import { TextInput, Button } from "../components";
import Cross from "../assets/images/svgs/cross.svg";

const Modal = ({ open, setOpen }) => {
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(true);
  const [form, setForm] = useState({ balance: 0 });

  const handleChange = (event) => {
    const { name, value } = event.target;
    // setForm((prev) => ({ ...prev, [name]: value }));

    if (value !== "") {
      setForm((prev) => ({
        ...prev,
        [name]: value >= 0 ? value : value <= 0 ? 0 : "",
      }));
    } else {
      setForm((prev) => ({ ...prev, [name]: value }));
    }
  };
  const handleSubmit = () => {
    const payload = {
      user_id: getCookies("user_id"),
      amount: form.balance,
    };
    dispatch(addBalance(payload))
      .unwrap()
      .then((res) => {
        console.log(28, res);
        setOpen({ open: !open.open, mode: open.mode });

        getUserLogin();
      })
      .catch((error) => {
        console.error("Failed to fetch user:", error);
      });
  };

  const getUserLogin = () => {
    const payload = {
      token: USER_TOKEN,
    };
    dispatch(userLogin(payload))
      .unwrap()
      .then((res) => {
        setCookies("token", res.data.token);
        setCookies("user_id", res.data._id);
        setCookies("wallet_balance", res.data.wallet_balance);
      })
      .catch((error) => {
        console.error("Failed to fetch user:", error);
      });
  };

  return (
    <div className={open.open ? `modal d-block` : `modal`}>
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header py-3 d-flex justify-content-between">
            <span className="fs-18 text-capitalize">
              {open.mode} Wallet Balance
            </span>

            <div
              className="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
            >
              <span
                className="svg-icon svg-icon-1"
                onClick={() => {
                  setOpen({ open: !open.open, mode: open.mode });
                }}
              >
                <img src={Cross} />
              </span>
            </div>
          </div>

          <div className="modal-body scroll-y m-1">
            <TextInput
              name={"balance"}
              title={"Balance"}
              type={"number"}
              value={form.balance}
              // error={error.betAmount}
              handleChange={handleChange}
              placeholder={"Balance"}
              additionalPre={false}
            />
            <div className=" mx-1 mx-sm-1 mx-md-2 mt-4">
              <Button
                name={"Submit"}
                type={"submit"}
                handleSubmit={handleSubmit}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Modal.prototype = {
  open: PropTypes.object,
  setOpen: PropTypes.func,
};
export default Modal;
