
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../../../helper/api";
import { toast } from "react-toastify";

export const getRiskLevel = createAsyncThunk("user/getRiskLevel", async (payload) => {
  const response = await api.post("user/get-risk-levels", payload);
  
  return response.data;
});

const getRiskLevelSlice = createSlice({
  name: "user",
  initialState: {
    data: null,
    loading: false,
    error: null,
  },
  reducers: {},
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: {
      ignoredPaths: ["payload.headers"],
    },
  }),
  extraReducers: (builder) => {
    builder
      .addCase(getRiskLevel.pending, (state) => {
        
        state.loading = true;
      })
      .addCase(getRiskLevel.fulfilled, (state, action) => {
      
      
        state.loading = false;
        state.data = action.payload.data;
      })
      .addCase(getRiskLevel.rejected, (state, action) => {
      
        state.loading = false;
        state.error = action.error.message;
        toast.error(action.error.message);
      });
  },
});

export default getRiskLevelSlice.reducer;
