import React from "react";
import PropTypes from "prop-types";

const HorizontalTextInput = ({
  name,
  title,
  type,
  value,
  error,
  handleChange,
  placeholder,
  className,
}) => {
  console.log(error);
  return (
    <div className="d-flex mb-6">
      <label className=" fw-semibold fs-12">{title}</label>

      <div className="w-100">
        <input
          type={type}
          name={name}
          className="form-control  form-control-solid mb-3 mb-lg-0"
          placeholder={placeholder}
          value={value}
        />
      </div>
    </div>
  );
};

HorizontalTextInput.prototype = {
  name: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.any,
  error: PropTypes.string,
  handleChange: PropTypes.func,
  placeholder: PropTypes.string,
  className: PropTypes.string,
};
export default HorizontalTextInput;
