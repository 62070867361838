
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../helper/api";

export const forgotPassword = createAsyncThunk("user/forgotPassword", async (payload) => {
  const response = await api.put("admin/change-password", payload);
  return response;
});

const forgotPasswordSlice = createSlice({
  name: "user",
  initialState: {
    data: null,
    loading: false,
    error: null,
  },
  reducers: {},
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: {
      ignoredPaths: ["payload.headers"],
    },
  }),
  extraReducers: (builder) => {
    builder
      .addCase(forgotPassword.pending, (state) => {
        
        state.loading = true;
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        console.log(26, action)
       
       
        state.loading = false;
        state.data = action.payload.data;
      })
      .addCase(forgotPassword.rejected, (state, action) => {
       
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default forgotPasswordSlice.reducer;
