
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../../../helper/api";
import { toast } from "react-toastify";

export const createBet = createAsyncThunk("user/createBet", async (payload) => {
  const response = await api.post("user/create-bet", payload);
 
  return response.data;
});

const createBetSlice = createSlice({
  name: "user",
  initialState: {
    data: null,
    loading: false,
    error: null,
  },
  reducers: {},
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: {
      ignoredPaths: ["payload.headers"],
    },
  }),
  extraReducers: (builder) => {
    builder
      .addCase(createBet.pending, (state) => {
        
        state.loading = true;
      })
      .addCase(createBet.fulfilled, (state, action) => {
      
       
        state.loading = false;
        state.data = action.payload.data.data;
      })
      .addCase(createBet.rejected, (state, action) => {
        
        state.loading = false;
        state.error = action.error.message;
        toast.error(action.error.message);
      });
  },
});

export default createBetSlice.reducer;
