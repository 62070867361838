import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import Edit from "../assets/images/svgs/edit.svg";
import Delete from "../assets/images/svgs/delete.svg";

const ImageFileInput = ({name,  file, handleFile}) => {
  const [profileImg, setProfileImg] = useState("");

  useEffect(() => {
    if(profileImg) {
      handleFile(name, profileImg)
    }
    
  }, [profileImg])

  return (
    <div className="row m-3 " style={{ position: "relative" }}>
      <div className="text-center">
        <div
          className="image-input image-input-outline"
          
          //   style={{ backgroundImage: "url('../assets/images/user.jpg')" }}
        >
        
          <label
            className="btn btn-icon p-0 btn-circle btn-active-color-primary bg-body shadow"
            data-kt-image-input-action="change"
            data-bs-toggle="tooltip"
            title="Change avatar"
            style={{ width: "125px", height: "125px" }}
          >
            <img
              src={file || profileImg}
              alt={"Avatar"}
              className={""}
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "0.475rem"
              }}
            />
            <i className="fa fa-pencil" aria-hidden="true"></i>

            <input
              className="d-none"
              type="file"
              name="avatar"
              accept=".png, .jpg, .jpeg"
              onChange={(e) => {
                const [file] = e.target.files || e.dataTransfer.files;
               
                setProfileImg(URL.createObjectURL(e.target.files[0]));
                
              }}
            />
            <input type="hidden" name="avatar_remove" />
          </label>

          <span
            className="svg-icon svg-icon-3 btn btn-icon btn-circle btn-active-color-primary file-icon-edit  bg-body shadow"
            title="Cancel avatar"
            onClick={() => {
              // setProfileImg('')
            }}
          >
            <img src={Edit} />
          </span>

          <span
            className="svg-icon svg-icon-3 btn btn-icon btn-circle btn-active-color-primary  file-icon-cancel bg-body shadow"
            title="Remove avatar"
            onClick={() => {
              setProfileImg('')
            }}
          >
            <img src={Delete} />
          </span>
        </div>

      
      </div>
    </div>
  );
};

ImageFileInput.prototype = {
  name: PropTypes.string,
  file: PropTypes.any,
  handleFile:PropTypes.func
};

export default ImageFileInput;
