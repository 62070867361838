import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import Cross from "../assets/images/svgs/cross.svg";

const RewardModal = ({ open, setOpen, reward }) => {
  const [modalOpen, setModalOpen] = useState(true);

  return open && (
    <div className="centered-div">
      <span>{reward?.num} X</span>

      <br />
      <span>{reward.amount}</span>
    </div>
  );
};

RewardModal.prototype = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  reward: PropTypes.object,
};
export default RewardModal;
