import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import api from "../../helper/api";
import { getCookies } from "../../helper/utils";
import axios from "axios";
import { toast } from "react-toastify";


export const getProfile = createAsyncThunk("user/getProfile", async () => {
  const response = await api.get(`admin/get-profile`, {
    headers: {
      Authorization: `Bearer ${getCookies("token")}`,
      "Content-Type": "application/json; charset=utf-8",
    },
  });
  console.log(26, response)

  return response.data; 
});


const getProfileSlice = createSlice({
  name: "user",
  initialState: {
    data: null,
    loading: false,
    error: null,
  },
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: {
      ignoredPaths: ["payload.headers"],
    },
  }),

  reducers: {},
  extraReducers: (builder) => {
   
    builder
      .addCase(getProfile.pending, (state) => {
        console.log(51, state)
        state.loading = true;
      })
      .addCase(getProfile.fulfilled, (state, action) => {
        
        state.loading = false;
        state.data = action.payload?.data;
      })
      .addCase(getProfile.rejected, (state, action) => {
        console.log(57, action)
        state.loading = false;
        state.error = action.error.message;
        toast.error(action.error.message);
      });
  },
});

export default getProfileSlice.reducer;
