import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import api from "../../helper/api";
import { toast } from "react-toastify";

export const updateProfile = createAsyncThunk("user/updateProfile", async (payload) => {

  const response = await api.put("admin/update", payload);
 
  return response;
});

const updateProfileSlice = createSlice({
  name: "user",
  initialState: {
    data:null,
    loading: false,
    error: null,
  },
  reducers: {},
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: {
      ignoredPaths: ["payload.headers"],
    },
  }),
  extraReducers: (builder) => {
    builder
      .addCase(updateProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(updateProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        toast.error(action.error.message);
      });
  },
});

export default updateProfileSlice.reducer;
