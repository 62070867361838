import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap/dist/css/bootstrap.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

import reportWebVitals from "./reportWebVitals";
import store from "./redux/store";
import DefaultRoutes from "./routes/routes";

import "./index.css";
import "./style.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ToastContainer autoClose={1500} />
    <Provider store={store}>
      {/* <iframe
        src={"https://kenof.staging-server.in/"}
        height={500}
        width={"100%"}
        sandbox="allow-scripts allow-same-origin"
      /> */}
      <DefaultRoutes />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
